import { request } from '@/utils/request-utils.js';

// ================================================================================================
// 海南关区
// ================================================================================================

// 获取 报文记录
export function apiGetMessageLogPage(data) {
    return request({
        url: `/api/admin/customs/hainan/message-logs`,
        method: 'get',
        params: data,
        token: true,
    })
}
// 获取 报文记录
// export function apiGetGoodsObject(data) {
//     return request({
//         url: `/api/admin/goods/goods/${data.goods_id}`,
//         method: 'get',
//         params: data,
//         token: true,
//     })
// }
// 添加 报文记录
export function apiInsertMessageLog(data) {
    return request({
        url: `/api/admin/customs/hainan/message-log`,
        method: 'post',
        data,
        token: true,
    })
}
// 操作 报文记录 加签
export function apiActionMessageLogSign(data) {
    return request({
        url: `/api/admin/customs/hainan/message-logs/${data.log_id}/actions/sign`,
        method: 'post',
        data,
        token: true,
    })
}
// 操作 报文记录 发送
export function apiActionMessageLogSend(data) {
    return request({
        url: `/api/admin/customs/hainan/message-logs/${data.log_id}/actions/send`,
        method: 'post',
        data,
        token: true,
    })
}
// 删除商品
// export function apiDeleteGoods(data) {
//     return request({
//         url: `/api/admin/goods/goods/${data.goods_id}`,
//         method: 'delete',
//         data,
//         token: true,
//     })
// }



// ================================================================================================
export function apiCustomsHainanDingdanStep1(data) {
    return request({
        url: `/api/admin/customs/hainan/dingdan/step1`,
        method: 'post',
        data,
        token: true,
    })
}
export function apiCustomsHainanDingdanStep2(data) {
    return request({
        url: `/api/admin/customs/hainan/dingdan/step2`,
        method: 'post',
        data,
        token: true,
    })
}


export function apiCustomsHainanQingdanStep1(data) {
    return request({
        url: `/api/admin/customs/hainan/qingdan/step1`,
        method: 'post',
        data,
        token: true,
    })
}
export function apiCustomsHainanQingdanStep2(data) {
    return request({
        url: `/api/admin/customs/hainan/qingdan/step2`,
        method: 'post',
        data,
        token: true,
    })
}