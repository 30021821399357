<template>
  <el-pagination
    v-if="epTotal > 0"
    background
    :current-page="epPage"
    :total="epTotal"
    :page-sizes="epPageSizes"
    :page-size="epPageSize"
    :pager-count="epPagerCount"
    :layout="epLayout"
    @current-change="onPageChange"
    @size-change="onLimitChange">
  </el-pagination> 
</template>

<script>
export default {
  name: "ep-pagination",
  props: {
    epPage: {
      type: Number,
      default: 1
    },
    epTotal: {
      type: Number,
      default: 0
    },
    epPageSizes: {
      default: [10, 20]
    },
    epPageSize: {
      type: Number,
      // default: 20
    },
    epPagerCount: {
      type: Number,
      default: 5
    },
    epLayout: {
      type: String,
      default: 'total, sizes, prev, pager, next' // , jumper
    }
  },
  emits: ['pagination-change'],
  data() {
    return {};
  },
  watch: {
  },
  methods: {
    // 改变页码
    onPageChange(page) {
      let event = 'page-change', args = page
      this.$emit('pagination-change', event, args)
    },
    // 改变每页记录数量
    onLimitChange(limit) {
      let event = 'limit-change', args = limit
      this.$emit('pagination-change', event, args)
    },
  },
  mounted() {
  }
};
</script>

<style scoped>
</style>
