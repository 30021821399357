import { request } from '@/utils/request-utils.js';


// ================================================================================================
// 申报 - 订单信息
// ================================================================================================

// 获取订单报关分页
export function apiV1GetOrdersDeclarationPage(data) {
    return request({url: `/api/merchant/v1/declaration/orders`, method: 'get', params: data, token: true})
}
// 获取订单报关对象
export function apiV1GetOrderDeclarationObject(data) {
    return request({url: `/api/merchant/v1/declaration/orders/${data.order_id}`, method: 'get', params: data, token: true})
}

// 获取订单报关分页 - OLD
export function apiGetOrdersDeclarationPage(data) {
    return request({url: `/api/merchant/declaration/orders`, method: 'get', params: data, token: true})
}
// 获取订单报关对象 - OLD
export function apiGetOrderDeclarationObject(data) {
    return request({url: `/api/merchant/declaration/order/${data.order_id}`, method: 'get', params: data, token: true})
}



// ================================================================================================
// 申报 - 申报处理
// ================================================================================================


// ================================================================================================

// 订单海关申报情况查询
export function apiGetCustomsDeclarationPage(data) {
    return request({
        url: `/api/merchant/declaration/customs/action/query-message`,
        method: 'get',
        params: data,
        token: true,
    })
}


// 操作 海关申报
export function apiActionCustomsDeclare(data) {
    return request({
        url: `/api/merchant/declaration/customs/action/declare`,
        method: 'get',
        params: data,
        token: true,
    })
}

// 操作 海关重报
export function apiActionCustomsRedeclare(data) {
    return request({url: `/api/merchant/declaration/customs/action/redeclare`, method: 'get', params: data, token: true})
}

// 操作 海关检索结果
export function apiActionCustomsQuery(data) {
    return request({
        url: `/api/merchant/declaration/customs/action/query`,
        method: 'get',
        params: data,
        token: true,
    })
}

// 操作 海关检索结果
export function apiActionCustomsCancel(data) {
    return request({
        url: `/api/merchant/declaration/customs/action/cancel`,
        method: 'get',
        params: data,
        token: true,
    })
}


// ================================================================================================


// 操作 支付申报
export function apiActionPaymentDeclare(data) {
    return request({
        url: `/api/merchant/declaration/payment/action/declare`,
        method: 'get',
        params: data,
        token: true,
    })
}

// 操作 支付检索结果
export function apiActionPaymentQuery(data) {
    return request({
        url: `/api/merchant/declaration/payment/action/query`,
        method: 'get',
        params: data,
        token: true,
    })
}


// ================================================================================================


// 操作 物流申报
export function apiActionLogisticsDeclare(data) {
    return request({
        url: `/api/merchant/declaration/logistics/action/declare`,
        method: 'get',
        params: data,
        token: true,
    })
}

// 操作 物流检索结果
export function apiActionLogisticsQuery(data) {
    return request({
        url: `/api/merchant/declaration/logistics/action/query`,
        method: 'get',
        params: data,
        token: true,
    })
}


// 操作 回写店铺运单号
export function apiActionRewriteWaybillNumber(data) {
    return request({
        url: `/api/merchant/declaration/logistics/action/rewrite-waybill-number`,
        method: 'get',
        params: data,
        token: true,
    })
}


// ================================================================================================
// 清单申报
// ================================================================================================

// 操作 清单申报
export function apiActionClearanceDeclare(data) {
    return request({url: `/api/merchant/declaration/clearance/action/declare`, method: 'get', params: data, token: true})
}

// ================================================================================================
// 仓储申报
// ================================================================================================

// 操作 仓储申报
export function apiActionWarehouseDeclare(data) {
    return request({url: `/api/merchant/declaration/warehouse/action/declare`, method: 'get', params: data, token: true})
}
// 操作 仓储查询
export function apiActionWarehouseQuery(data) {
    return request({url: `/api/merchant/declaration/warehouse/action/query`, method: 'get', params: data, token: true})
}



// ================================================================================================
// 申报 - 推送管理 V1
// ================================================================================================
// 操作 通知推送
export function apiV1NoticeActionPush(data) { return request({url: `/api/admin/v1/declaration/notice/actions/push`, method: 'post', data, token: true}) }
