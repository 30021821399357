<template>
  <span>
    <template v-if="this.value == 'YouzanPlatform'">
      <span class="el-tag el-tag--danger el-tag--plain el-tag--small">有赞</span>
    </template>
    <template v-else-if="this.value == 'PddPlatform'">
      <span class="el-tag el-tag--warning el-tag--plain el-tag--small">拼多多</span>
    </template>
    <template v-else-if="this.value == 'YmatouPlatform'">
      <span class="el-tag el-tag--light el-tag--plain el-tag--small">洋码头</span>
    </template>
    <template v-else-if="this.value == 'WeimobPlatform'">
      <span class="el-tag el-tag--success el-tag--plain el-tag--small">微盟</span>
    </template>
    <template v-else-if="this.value == 'WeidianPlatform'">
      <span class="el-tag el-tag--danger el-tag--plain el-tag--small">微店</span>
    </template>
    <template v-else-if="this.value == 'SelfbuiltPlatform'">
      <span class="el-tag el-tag--danger el-tag--plain el-tag--small">自建</span>
    </template>
    <template v-else-if="this.value == 'XiaohongshuPlatform'">
      <span class="el-tag el-tag--danger el-tag--plain el-tag--small">小红书</span>
    </template>
    <template v-else>
      <span class="el-tag el-tag--info el-tag--plain el-tag--small">其他</span>
    </template>
  </span>
</template>

<script>
export default {
  name: "source-code-tag",
  props: [
    'value',
  ],
  data() {
    return {};
  },
  methods: {
  },
  mounted() {
  },
  watch: {
  }
};
</script>

<style scoped>
</style>
