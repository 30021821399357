<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>申报</el-breadcrumb-item><el-breadcrumb-item>申报管理</el-breadcrumb-item><el-breadcrumb-item>订单申报</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter"></div>
      <div class="ep-page-header-toolbar">
        <!-- <el-button type="primary" @click="onAddClick">添加商户</el-button> -->
      </div>
    </el-header>

    <!-- 其他窗体 START -->
    <!-- 广州 - 申报查询框 -->
    <GzQueryDialog ref="refGzQueryDialog" />
    <!-- 海南 - 订单报文报错 -->
    <HnMessageDialog ref="refHnMessageDialog" />
    
    <OrderDetailDialog ref="refOrderDetailDialog" />
    <!-- 其他窗体 END -->

    <el-main class="ep-page-main">
      <!-- 查询面板 START -->
      <OrderSearchPanel ref="searchView" @search="onSearched" @reset="onSearchReset"/>
      <!-- 查询面板 END -->

      <!-- 数据表格 START -->
      <div class="el-card" style="margin:10px 0 80px 0;">
        <!-- 数据表格-过滤标签栏 START -->
        <el-tabs type="card" v-model="dataTableFilter" @tab-change="onDataTableTabChange">
          <el-tab-pane label="全部" name="__ALL__"></el-tab-pane>
          <el-tab-pane label="未申报" name="0"></el-tab-pane>
          <el-tab-pane label="申报中" name="1"></el-tab-pane>
          <el-tab-pane label="已通过" name="2"></el-tab-pane>
          <el-tab-pane label="未通过" name="3"></el-tab-pane>
        </el-tabs>
        <!-- 数据表格-过滤标签栏 END -->

        <!-- 数据表格 START -->
        <div class="ep-table-panel-wrap">
          <el-table ref="epDataTable" fit stripe highlight-current-row size="small" v-loading="epIsLoading"
            :data="epDataList" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            @selection-change="onTableSelectionChange2">

            <el-table-column type="selection" width="50" />

            <!-- <el-table-column type="expand">
              <template #default="scope">
                <OrderExpandDetailView :id="scope.row.order_id" />
              </template>
            </el-table-column> -->

            <el-table-column label="订单编号" min-width="250">
              <template #default="scope">
                <el-link :underline="false" @click="onDetailClick(scope.row.order_id)">{{ scope.row.order_sn }}</el-link>
                <div class="ep-cell-subtitle">{{ scope.row.main_order_sn }}<el-tag v-if="scope.row.twf_is_auto_workflow == 1" style="margin-left:4px;">自动</el-tag></div>
              </template>
            </el-table-column>

            <!-- <el-table-column label="所属商户/店铺" min-width="150">
              <template #default="scope">
                <span class="ep-overflow-text"><source-code-tag :value="scope.row.source_code" />{{ scope.row.shop_name }}</span>
                <div class="ep-cell-subtitle ep-overflow-text">{{ scope.row.merchant_name }}</div>
              </template>
            </el-table-column> -->

            <el-table-column label="下单时间" min-width="100">
              <template #default="scope">
                <span v-html="$$utils.toDataTimeHtml(scope.row.order_time)"></span>
              </template>
            </el-table-column>
            
            <el-table-column label="收件人信息" min-width="120" >
              <template #default="scope">
                <div class="ep-overflow-text">
                  <template v-if="$$auth.hasPermission('A0009')">{{ scope.row.consignee_name }}</template><template v-else>{{ scope.row.mask_consignee_name }}</template>
                </div>
                <div class="ep-overflow-text">
                  <template v-if="$$auth.hasPermission('A0009')">{{ scope.row.consignee_telephone }}</template><template v-else>{{ scope.row.mask_consignee_telephone }}</template>
                </div>
              </template>
            </el-table-column>

            <!-- <el-table-column prop="pay_amount" label="支付金额" min-width="80" /> -->

            <!-- <el-table-column label="订单状态" min-width="100" >
              <template #default="scope">
                <order-state-tag :state="scope.row.order_state" />
              </template>
            </el-table-column> -->

            <el-table-column label="支付申报" min-width="100" >
              <template #default="scope">
                <PaymentResultTag :row="scope.row" />
              </template>
            </el-table-column>

            <el-table-column label="订单申报" min-width="100" >
              <template #default="scope">
                <CustomsResultTag effect="dark" :row="scope.row" />
              </template>
            </el-table-column>

            <el-table-column label="操作" min-width="150" fixed="right">
              <template #default="scope">
                <!-- <span class="ep-action-button primary" @click="onDeclareClick([scope.row.order_id])">申报</span> -->
                <!-- <span class="ep-action-line"/> -->
                
                <!-- <template v-if="scope.row.customs_app_code == 'NingboCustoms'">
                  <div><span class="ep-action-button primary" @click="onDeclareClick([scope.row.order_id])">申报</span></div>
                  <div><span class="ep-action-button primary" @click="onCancelClick([scope.row.order_id])">取消</span></div>
                </template>
                <template v-else-if="scope.row.customs_app_code == 'WuhuCustoms' || scope.row.customs_app_code == 'ChengduCustoms' || scope.row.customs_app_code == 'MianyangCustoms'">
                  <div><span class="ep-action-button primary" @click="onDeclareClick([scope.row.order_id])">申报</span></div>
                </template>
                <template v-else-if="scope.row.customs_app_code == 'HainanCustoms'">
                  <div><span class="ep-action-button primary" @click="onDeclareHannanExClick(scope.row)">申报海南订单</span></div>
                  <div><span class="ep-action-button primary" @click="onDeclareHannanClick(scope.row)">报文操作</span></div>
                </template>
                <template v-else-if="scope.row.customs_app_code == 'ShandongCustoms2'">
                  <div><span class="ep-action-button primary" @click="onMakeCEBXmlClick([scope.row.order_id])">生成报文</span></div>
                </template>
                <template v-else>
                  <div><span class="ep-action-button primary" @click="onMakeKjXmlClick([scope.row.order_id])">生成报文</span></div>
                  <div><span class="ep-action-button primary" @click="onQueryMessageListClick(scope.row.order_id, scope.row.order_sn)">查询</span></div>
                </template> -->

                <!-- TEST -->
                <!-- <div><span class="ep-action-button primary" @click="onGzQueryDialogClick(scope.row.order_id, scope.row.order_sn)">查询</span></div> -->

                <template v-for="(operElement) in scope.row.operations" :key="operElement.code">
                  <span class="ep-action-button primary" @click="onPluginOperClick(operElement.code, [scope.row.order_id], scope.row)">{{ operElement.name }}</span>
                </template>

              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 数据表格 END -->
      </div>
      <!-- 数据表格面板 END -->


      <!-- 数据页面固定footer START -->
      <div class="ep-float-footer">
        <div class="ep-float-footer-toolbar">
          <el-button plain class="ep-button-select-all" @click="$refs.epDataTable.toggleAllSelection()">
            <span class="ep-button-select-all-text1">已选中 <span class="ep-button-select-all-text2">{{ epMultipleSelection.length }}</span> / <span class="ep-button-select-all-text2">{{ epDataList.length }}</span> 项</span>
          </el-button>
          
          <!-- 动态批量操作 START -->
          <template v-for="(oper) in operationList" :key="oper">
            <el-button type="primary" :disabled="batchOperationDisableMap.get(oper.code)" @click="onPluginOperClick(oper.code)">{{ oper.name }}</el-button> 
          </template>
          <!-- 动态批量操作 END -->
          
        </div>
        <div class="ep-float-footer-pager">
          <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
        </div>
      </div>
      <!-- 数据页面固定footer END -->

    </el-main>

  </el-container>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Edit, ArrowDown, MoreFilled } from '@element-plus/icons-vue'
//
import GzQueryDialog from '@/views/v1/declaration/customs/guangzhou/GzQueryDialog'
import HnMessageDialog from '@/views/v1/declaration/customs/hainan/HnMessageDialog'
//
import OrderDetailDialog from '@/views/v1/declaration/order/common/OrderDetailDialog'
import OrderSearchPanel from '@/views/v1/declaration/order/common/OrderSearchPanel'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import OrderStateTag from '@/components/v1/trade/tags/OrderStateTag'
import CustomsResultTag from '@/components/v1/declaration/tags/CustomsResultTag'
import PaymentResultTag from '@/components/v1/declaration/tags/PaymentResultTag'
import { apiGetOrdersDeclarationPage, apiActionCustomsDeclare, apiActionCustomsQuery, apiActionCustomsCancel } from '@/api/declaration.js'
import { apiV1GetOrdersDeclarationPage } from '@/api/declaration.js'
import { apiCustomsHainanDingdanStep1, apiCustomsHainanDingdanStep2 } from '@/api/plugin_customs.js'
import AuthUtils from '@/utils/auth-utils.js'
import JxgocsUtils from '@/utils/jxgocs-utils.js'
import StorageUtils from '@/utils/storage-utils.js'
import GlobalConfig from '@/config/index.js'


export default {
  mixins: [ DataTableMixin ],
  name: "order-list-page",
  components: {
    ArrowDown, // Edit,
    //
    GzQueryDialog,
    HnMessageDialog,
    //
    OrderDetailDialog,
    OrderSearchPanel,
    SourceCodeTag,
    OrderStateTag,
    CustomsResultTag,
    PaymentResultTag,
  },
  props: [],
  data() {
    return {
      // shop_id: '',
      // current_merchant_id: '',
      // current_shop_id: '',
      dataTableFilter: '__ALL__',
      //
      operationList: [],
      batchOperationDisableMap: new Map(),
      //
      WEportClient: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    getFilter() {
      let data = {
        shop_id: AuthUtils.getCurrentShopId(),
        declaration_type: 'customs',
      }
      if (this.dataTableFilter != '' && this.dataTableFilter != '__ALL__') {
        data.customs_result = this.dataTableFilter
      }
      return data
    },
    getPage({reload = false} = {}) {
      let paginationData = this.getPagination(reload)
      let filterData = this.getFilter()
      let searchData = this.$refs.searchView.getSearchFilter() 
      let queryData = Object.assign(paginationData, filterData, searchData)

      this.epIsLoading = true
      apiV1GetOrdersDeclarationPage(queryData).then((res) => {
        this.epDataList = res.data.data
        this.setPaginationTotal(res.data.pagination.total)
        this.operationList = JxgocsUtils.getOperationList(this.epDataList)
        this.batchOperationDisableMap = JxgocsUtils.getBatchOperationDisableMap(this.operationList, this.epMultipleSelection)
        this.epIsLoading = false
      })
      
    },

    // ===================================================================================================
    
    // 回调触发 - 查询
    onSearched(data) {
      this.getPage({reload: true})
    },
    // 回调触发 - 查询重置
    onSearchReset() {
      this.getPage({reload: true})
    },
    // Change触发 - 数据表Tab
    onDataTableTabChange(name) {
      this.getPage({reload: true})
    },
    // 点击触发 - 订单详情
    onDetailClick(id) {
      this.$refs.refOrderDetailDialog.showView({ editMode: "update", id: id })
    },
    // 表格选择触发 - 替换默认多选实现动态菜单
    onTableSelectionChange2(val) {
      this.epMultipleSelection = val
      this.batchOperationDisableMap = JxgocsUtils.getBatchOperationDisableMap(this.operationList, this.epMultipleSelection)
    },
    // 点击触发 - 插件操作
    onPluginOperClick(code, inIds = [], rowObj = null) {
      // console.log(code); 
      console.log(inIds)
      switch (code) {
        case 'customs.declare':
          this.onDeclareClick(inIds)
          break;
        case 'customs.query':
          this.onQueryClick(inIds)
          break;
        case 'customs.cancel':
          this.onCancelClick(inIds)
          break;
        case 'customs.redeclare':
          this.onRedeclareClick(inIds)
          break;
        //
        case 'customs.declare-message':
          this.onDeclareMessageClick(inIds)
          break;
        case 'customs.redeclare-message':
          // this.onDeclareMessageClick(inIds)
          break;  
          
        // 广州
        case 'customs.gz-query-dialog':
          this.onGzQueryDialogClick(rowObj)
          break;
        

        // 海南
        case 'customs.hn-declare':
          this.onDeclareHannanExClick(rowObj)
          break;
        case 'customs.hn-message-dialog':
          this.onDeclareHannanClick(rowObj)
          break;
      }
    },



    // ===================================================================================================

    // 点击触发 - 申报
    onDeclareClick(inIds = []) {
      var ids = this.getTableSelectedIds(inIds, (val) => val.order_id)
      var apiParams = { ids: ids }
      apiActionCustomsDeclare(apiParams).then((res) => {
        this.getPage()
        this.$message.success("操作成功")
      })
    },
    // 点击触发 - 重报
    onRedeclareClick(inIds = []) {
      var ids = this.getTableSelectedIds(inIds, (val) => val.order_id)
      var apiParams = { ids: ids, exec_params1: 1 }
      apiActionCustomsDeclare(apiParams).then((res) => {
        this.getPage()
        this.$message.success("操作成功")
      })
    },
    // 点击触发 - 查询
    onQueryClick(inIds = []) {
      var ids = this.getTableSelectedIds(inIds, (val) => val.order_id)
      apiActionCustomsQuery({
        ids: ids,
      }).then((res) => {
        this.getPage()
        this.$message.success("操作成功")
      })
    },
    // 点击触发 - 取消
    onCancelClick(inIds = []) {
      var ids = this.getTableSelectedIds(inIds, (val) => val.order_id)
      apiActionCustomsCancel({
        ids: ids,
      }).then((res) => {
        this.getPage()
        this.$message.success("操作成功")
      })
    },

    // 点击触发 - 申报报文
    onDeclareMessageClick(inIds = []) {
      console.log(inIds)
      var dqsh = StorageUtils.get('ref_id')
      // 312020081218013517456600ba12a18f whsg 微好时光
      // 3120210316185420175514001a44e7bf hznagou 杭州挪购
      // 3120200221124900730967008238c19c tuoling 福州驼铃
      if (dqsh == '312020081218013517456600ba12a18f' || dqsh == "3120210316185420175514001a44e7bf" || dqsh == '3120200221124900730967008238c19c') {
        this.onMakeKJXmlClick(inIds)
      } else {
        this.onMakeCEBXmlClick(inIds)
      }
    },



    // 更多按钮触发
    // onMoreButtonClick(commandObj) {
    //   // console.log(commandObj)
    //   switch (commandObj.index) {
    //     case 'redeclare':
    //       this.onDeclareClick([commandObj.id], 1)
    //       break;
    //   }
    // },


    // ===================================================================================================
    // 特殊操作
    // ===================================================================================================

    // 点击触发 - 广州 - 申报情况查询框
    onGzQueryDialogClick(row) {
      this.$refs.refGzQueryDialog.showView({ editMode: "update", id: row.order_id, order_sn: row.order_sn })
    },

    // 生成KJ报文操作响应
    onMakeKJXmlClick(inIds = []) {
      var ids = this.getTableSelectedIds(inIds, (val) => val.order_id)
      var url = `${GlobalConfig.host}api/admin/declaration/customs/action/makeKJXml?ids=${ids}`
      window.open(url) 
    },

    // 生成CEB报文操作响应
    onMakeCEBXmlClick(inIds = []) {
      console.log(inIds)
      var ids = this.getTableSelectedIds(inIds, (val) => val.order_id)
      console.log(ids)
      var url = `${GlobalConfig.host}api/admin/declaration/customs/action/makeCEBXml?ids=${ids}&exec_params1=21`
      window.open(url) 
    },

    // 点击触发 - 海南 - 报文操作框
    onDeclareHannanClick(row) {
      this.$refs.refHnMessageDialog.showView({ editMode: "update", id: row.order_id, inOrderObject: row })
    },

    // 点击触发 - 海南 - 申报订单
    onDeclareHannanExClick(row) {
      let vueThis = this
      var ukeyPassword = StorageUtils.get('ukp-' + AuthUtils.getUserId())
      console.log(ukeyPassword)
      if (ukeyPassword == undefined || ukeyPassword == '') { vueThis.$message.error("请先输入UKey密码"); return }

      // 生成报文
      apiCustomsHainanDingdanStep1({
        order_id: row.order_id,
      }).then((res) => {
        console.log(res.data)
        var log_id = res.data.data.log_id
        var to_sign_content = res.data.data.to_sign_content
        var message_type = res.data.data.message_type
        var signature_value = ''
        var key_name = ''
        
        // 加签
        this.WEportClient.isInstalledTest(this.WEportClient.cusSpcSignDataAsPEM, to_sign_content, ukeyPassword, function(msg2, msgJson2){
          console.log(msgJson2)
          if (msg2.Result) {
            signature_value = msg2.Data[0]
            key_name = msg2.Data[1]
            // console.log(signature_value); console.log(key_name)
            
            // 保存并推送
            apiCustomsHainanDingdanStep2({
              log_id: log_id,
              message_type: message_type,
              signature_value: signature_value,
              key_name: key_name,
            }).then((res) => {
              vueThis.$message.success("操作成功")
              vueThis.getPage()
            })
          } else {
            vueThis.$message.error(msg2.Error[0])
          }
        })
        
      })
      

    },

    // ===================================================================================================


  },
  mounted() {
    this.WEportClient = window.EportClient
    this.$nextTick(() => {
      this.getPage()
    })

    // this.$$emitter.on('shopIdChanged', (shop_id) => {
    //   // console.log(shop_id)
    //   this.current_shop_id = shop_id
    //   this.getPage()
    // })
  }
}
</script>

<style>
</style>
