<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>申报</el-breadcrumb-item><el-breadcrumb-item>运单信息管理</el-breadcrumb-item><el-breadcrumb-item>运单处理</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter"></div>
      <div class="ep-page-header-toolbar">
        <!-- <el-button type="primary" @click="onAddClick">添加商户</el-button> -->
      </div>
    </el-header>

    <!-- 其他窗体 START -->
    <OrderDetailDialog ref="refOrderDetailDialog" />
    <LogisticsDetailQueryDialog ref="refLogisticsDetailQueryDialog" />
    <!-- 其他窗体 END -->

    <el-main class="ep-page-main">
      <!-- 查询面板 START -->
      <OrderSearchPanel ref="searchView" @search="onSearched" @reset="onSearchReset"/>
      <!-- 查询面板 END -->

      <!-- 数据表格 START -->
      <div class="el-card" style="margin:10px 0 80px 0;">
        <!-- 数据表格-过滤标签栏 START -->
        <el-tabs type="card" v-model="dataTableFilter" @tab-change="onDataTableTabChange">
          <el-tab-pane label="全部" name="__ALL__"></el-tab-pane>
          <el-tab-pane label="未获取运单" name="0"></el-tab-pane>
          <el-tab-pane label="待回写" name="1"></el-tab-pane>
          <el-tab-pane label="已回写" name="2"></el-tab-pane>
          <!-- <el-tab-pane label="回写成功" name="3"></el-tab-pane> -->
        </el-tabs>
        <!-- 数据表格-过滤标签栏 END -->

        <!-- 数据表格 START -->
        <div class="ep-table-panel-wrap">
          <el-table ref="epDataTable" fit stripe highlight-current-row size="small" v-loading="epIsLoading"
            :data="epDataList" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            @selection-change="onTableSelectionChange2">

            <el-table-column type="selection" width="50" />

            <!-- <el-table-column type="expand">
              <template #default="scope">
                <OrderExpandDetailView :id="scope.row.order_id" />
              </template>
            </el-table-column> -->

            <el-table-column label="订单编号" min-width="250">
              <template #default="scope">
                <el-link :underline="false" @click="onDetailClick(scope.row.order_id)">{{ scope.row.order_sn }}</el-link>
                <div class="ep-cell-subtitle">{{ scope.row.main_order_sn }}<el-tag v-if="scope.row.twf_is_auto_workflow == 1" style="margin-left:4px;">自动</el-tag></div>
              </template>
            </el-table-column>

            <!-- <el-table-column label="所属商户/店铺" min-width="150">
              <template #default="scope">
                <span class="ep-overflow-text"><source-code-tag :value="scope.row.source_code" />{{ scope.row.shop_name }}</span>
                <div class="ep-cell-subtitle ep-overflow-text">{{ scope.row.merchant_name }}</div>
              </template>
            </el-table-column> -->

            <el-table-column label="下单时间" min-width="100">
              <template #default="scope">
                <span v-html="$$utils.toDataTimeHtml(scope.row.order_time)"></span>
              </template>
            </el-table-column>
            
            <el-table-column label="收件人信息" min-width="120" >
              <template #default="scope">
                <div class="ep-overflow-text">
                  <template v-if="$$auth.hasPermission('A0009')">{{ scope.row.consignee_name }}</template><template v-else>{{ scope.row.mask_consignee_name }}</template>
                </div>
                <div class="ep-overflow-text">
                  <template v-if="$$auth.hasPermission('A0009')">{{ scope.row.consignee_telephone }}</template><template v-else>{{ scope.row.mask_consignee_telephone }}</template>
                </div>
              </template>
            </el-table-column>

            <!-- <el-table-column prop="pay_amount" label="支付金额" min-width="80" /> -->

            <el-table-column label="订单/支付申报" min-width="150" >
              <template #default="scope">
                <CustomsResultTag :row="scope.row" />
                <PaymentResultTag :row="scope.row" />
              </template>
            </el-table-column>

            <el-table-column label="物流申报" min-width="100" >
              <template #default="scope">
                <LogisticsResultTag effect="dark" :row="scope.row" />
              </template>
            </el-table-column>

            <el-table-column label="运单信息" min-width="100" >
              <template #default="scope">
                <el-popover v-if="scope.row.logistics_result != 0" placement="top" width="250" trigger="hover">
                  <template #reference>{{ scope.row.waybill_number == null ? '---' : scope.row.waybill_number }}</template>
                  <div>
                    <div v-if="scope.row.hl_status">状态：{{ scope.row.hl_status }}</div>
                    <div v-if="scope.row.express_code">物流编码：{{ scope.row.express_code }}</div>
                    <div>运单号：{{ scope.row.waybill_number }}</div>
                  </div>
                </el-popover>
                <div v-if="scope.row.has_rewrite_waybill_number > 0">
                 <el-tag effect="dark" type="success">运单号已回写</el-tag>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="操作" min-width="150" fixed="right">
              <template #default="scope">
                <!-- <span class="ep-action-button primary" @click="onDeclareClick([scope.row.order_id])">申报</span>
                <template v-if="scope.row.logistics_app_code == 'KeyponLogistics' || scope.row.logistics_app_code == 'YangdabanThirdparty' || scope.row.logistics_app_code == 'KuajingtongLogistics'">
                  <span class="ep-action-line"/>
                  <span class="ep-action-button primary" @click="onQueryClick([scope.row.order_id])">查询</span>
                </template> -->

                <!-- <template v-for="(oper) in scope.row.operations" :key="oper.code">
                  <span class="ep-action-button primary" v-permission="oper.permission" @click="onPluginOperClick(oper.code, [scope.row.order_id], scope.row)">{{ oper.name }}</span>
                </template> -->

                <template v-if="scope.row.waybill_number != '' && scope.row.waybill_number != null">
                  <span class="ep-action-button primary" @click="onRewriteWaybillNumberClick([scope.row.order_id])">回写</span>
                </template>

                <template v-if="scope.row.logistics_app_code == 'CainiaoLogistics' && scope.row.logistics_extra_data">
                  <!-- <span class="ep-action-line"/> -->
                  <span class="ep-action-button primary" @click="onQueryLogisticsDetailClick(scope.row.order_id)">物流轨迹</span>
                </template>

                <template v-if="scope.row.logistics_app_code == 'CainiaoCCLogistics' && scope.row.logistics_extra_data">
                  <!-- <span class="ep-action-line"/> -->
                  <span class="ep-action-button primary" @click="onQueryLogisticsDetailClick(scope.row.order_id)">物流轨迹</span>
                </template>

                <template v-if="$$auth.hasPermission('B0002') && scope.row.print_uri">
                  <span class="ep-action-button primary" @click="onPrintClick(scope.row)">面单</span>
                </template>

                <template v-if="scope.row.logistics_app_code == 'JdLogistics'">
                  <!-- <span class="ep-action-line"/> -->
                  <span class="ep-action-button primary" @click="onGetPrintClick(scope.row)">面单</span>
                </template>

                <template v-if="scope.row.logistics_app_code == 'CainiaoLogistics' || scope.row.logistics_app_code == 'CainiaoCCLogistics'">
                  <template v-if="$$auth.hasPermission('B0003')">
                    <span class="ep-action-button primary" @click="onCainiaoGetPrintClick(scope.row)">面单</span>
                  </template>
                </template>

              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 数据表格 END -->
      </div>
      <!-- 数据表格面板 END -->


      <!-- 数据页面固定footer START -->
      <div class="ep-float-footer">
        <div class="ep-float-footer-toolbar">
          <el-button plain class="ep-button-select-all" @click="$refs.epDataTable.toggleAllSelection()">
            <span class="ep-button-select-all-text1">已选中 <span class="ep-button-select-all-text2">{{ epMultipleSelection.length }}</span> / <span class="ep-button-select-all-text2">{{ epDataList.length }}</span> 项</span>
          </el-button>
          <!-- <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onDeclareClick">申报</el-button>
          <el-button type="primary" icon="el-icon-edit" :disabled="getMultipleSelectionCount() == 0" @click="onQueryClick">查询</el-button> -->
          
          <!-- 动态批量操作 START -->
          <!-- <template v-for="(oper) in operationList" :key="oper">
            <el-button type="primary" v-permission="oper.permission" :disabled="batchOperationDisableMap.get(oper.code)" @click="onPluginOperClick(oper.code)">{{ oper.name }}</el-button> 
          </template> -->
          <!-- 动态批量操作 END -->
          <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onRewriteWaybillNumberClick">回写</el-button>

        </div>
        <div class="ep-float-footer-pager">
          <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
        </div>
      </div>
      <!-- 数据页面固定footer END -->

    </el-main>

  </el-container>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import permission from '@/directive/PermissionDirective.js'
import { Edit, ArrowDown, MoreFilled } from '@element-plus/icons-vue'
import OrderDetailDialog from '@/views/v1/declaration/order/common/OrderDetailDialog'
import OrderSearchPanel from '@/views/v1/declaration/order/common/OrderSearchPanel'
import LogisticsDetailQueryDialog from '@/views/v1/cainiao_logistics/logistics_detail/LogisticsDetailQueryDialog'

import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import OrderStateTag from '@/components/v1/trade/tags/OrderStateTag'
import CustomsResultTag from '@/components/v1/declaration/tags/CustomsResultTag'
import PaymentResultTag from '@/components/v1/declaration/tags/PaymentResultTag'
import LogisticsResultTag from '@/components/v1/declaration/tags/LogisticsResultTag'
import { apiGetOrdersDeclarationPage, apiActionLogisticsDeclare, apiActionLogisticsQuery, apiActionRewriteWaybillNumber } from '@/api/declaration.js'
import { apiV1GetOrdersDeclarationPage } from '@/api/declaration.js'
import { apiOrdersActionCancel } from '@/api/trade.js'
import AuthUtils from '@/utils/auth-utils.js'
import JxgocsUtils from '@/utils/jxgocs-utils.js'
import GlobalConfig from '@/config/index.js'


export default {
  mixins: [ DataTableMixin ],
  directives: { permission },
  name: "order-list-page",
  components: {
    // Edit,
    ArrowDown,
    OrderDetailDialog,
    OrderSearchPanel,
    LogisticsDetailQueryDialog,
    SourceCodeTag,
    OrderStateTag,
    CustomsResultTag,
    PaymentResultTag,
    LogisticsResultTag,
  },
  props: [],
  data() {
    return {
      // shop_id: '',
      // current_merchant_id: '',
      // current_shop_id: '',
      //
      dataTableFilter: '__ALL__',
      //
      operationList: [],
      batchOperationDisableMap: new Map(),
    }
  },
  computed: {},
  watch: {},
  methods: {
    getFilter() {
      let data = {
        shop_id: AuthUtils.getCurrentShopId(),
        declaration_type: 'waybill',
      }
      if (this.dataTableFilter != '' && this.dataTableFilter != '__ALL__') {
        data.waybill_mode = this.dataTableFilter
      }
      return data
    },
    getPage({reload = false} = {}) {
      let paginationData = this.getPagination(reload)
      let filterData = this.getFilter()
      let searchData = this.$refs.searchView.getSearchFilter() 
      let queryData = Object.assign(paginationData, filterData, searchData)

      this.epIsLoading = true
      apiV1GetOrdersDeclarationPage(queryData).then((res) => {
        this.epDataList = res.data.data
        this.setPaginationTotal(res.data.pagination.total)
        // this.operationList = JxgocsUtils.getOperationList(this.epDataList)
        // this.batchOperationDisableMap = JxgocsUtils.getBatchOperationDisableMap(this.operationList, this.epMultipleSelection)
        this.epIsLoading = false
      })
      
    },

    // ===================================================================================================
    
    // 回调触发 - 查询
    onSearched(data) {
      this.getPage({reload: true})
    },
    // 回调触发 - 查询重置
    onSearchReset() {
      this.getPage({reload: true})
    },
    // Change触发 - 数据表Tab
    onDataTableTabChange(name) {
      this.getPage({reload: true})
    },
    // 点击触发 - 订单详情
    onDetailClick(id) {
      this.$refs.refOrderDetailDialog.showView({ editMode: "update", id: id })
    },
    // 表格选择触发 - 替换默认多选实现动态菜单
    onTableSelectionChange2(val) {
      this.epMultipleSelection = val
      this.batchOperationDisableMap = JxgocsUtils.getBatchOperationDisableMap(this.operationList, this.epMultipleSelection)
    },
    // 点击触发 - 插件操作
    onPluginOperClick(code, inIds = [], rowObj = null) {
      // console.log(code); console.log(inIds)
      // switch (code) {
      //   case 'logistics.declare':
      //     this.onDeclareClick(inIds)
      //     break;
      //   case 'logistics.query':
      //     this.onQueryClick(inIds)
      //     break;
      //   case 'logistics.cancel':
      //     this.onCancelClick(inIds)
      //     break;
      // }
    },

    // ===================================================================================================

    // 点击触发 - 回写订单号
    onRewriteWaybillNumberClick(inIds = []) {
      var ids = this.getTableSelectedIds(inIds, (val) => val.order_id)
      apiActionRewriteWaybillNumber({
        ids: ids,
      }).then((res) => {
        this.getPage()
        this.$message.success("操作成功")
      })
    },
    // 点击触发 - 打印文件
    onPrintClick(rowObj) {
      var url = rowObj.print_uri
      window.open(url) 
    },
    onGetPrintClick(rowObj) {
      // console.log(inIds)
      // var ids = this.getTableSelectedIds(inIds, (val) => val.order_id)
      // console.log(ids)
      var url = `${GlobalConfig.host}api/admin/declaration/logistics/action/get-print?id=${rowObj.order_id}`
      window.open(url) 
    },
    onCainiaoGetPrintClick(rowObj) {
      // console.log(inIds)
      // var ids = this.getTableSelectedIds(inIds, (val) => val.order_id)
      // console.log(ids)
      var url = `${GlobalConfig.host}api/admin/declaration/logistics/action/get-print2?id=${rowObj.order_id}`
      window.open(url) 
    },
    // 点击触发 - 订单详情
    onQueryLogisticsDetailClick(id) {
      this.$refs.refLogisticsDetailQueryDialog.showView({ editMode: "view", id: id })
    },

    // ===================================================================================================

  },
  mounted() {
    this.$nextTick(() => {
      this.getPage()
    })

    // this.$$emitter.on('shopIdChanged', (shop_id) => {
    //   // console.log(shop_id)
    //   this.current_shop_id = shop_id
    //   this.getPage()
    // })
  }
}
</script>

<style>
</style>
