<template>
  <el-dialog v-model="viewVisible" width="400px" :show-close="false" custom-class="ep-dialog" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">

    <template #header="{ titleId, titleClass }">
      <div class="m-header">
        <span :id="titleId" :class="titleClass">导入清单状态：</span>
        <div>
          <!-- <el-button type="primary" plain><el-icon><Link/></el-icon><span>在新窗口打开</span></el-button> -->
          <el-button plain @click="viewVisible = false"><el-icon><Close/></el-icon><span>关闭</span></el-button>
        </div>
      </div>
    </template>

    <el-card>
      <!-- <div class="es-card-title">模板文件上传</div> -->
      <el-form ref="formUpload" :model="formModel" label-width="100px" v-loading="isLoading" status-icon>
        
        <div class="m-file-wrap">
          <el-upload name="clearanceResultFile" ref="uploadView" drag :auto-upload="false" multiple :limit="1" :action="uploadUrl"
            :on-success="onUploadSuccess" :on-error="onUploadError">
            <el-icon class="el-icon--upload"><UploadFilled/></el-icon>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <template #tip>
              <div class="el-upload__tip">只能上传xls/xlsx文件</div>
              <div class="el-upload__tip">
                <el-link type="primary" href='static/output_template/declaration_clearance_result_202205.xlsx' download="清关结果导入模板202205.xlsx">此处可下载模板</el-link>
              </div>
            </template>
          </el-upload>
          <el-button type="primary" class="m-upload-button" :loading="isUploading" @click="onUploadClick"><span>导入</span></el-button>
        </div>
      </el-form>
    </el-card>

    <!-- <template #footer>
      <el-button type="primary" @click="onSubmit"><el-icon><Check/></el-icon><span>提交</span></el-button>
      <el-button type="default" @click="viewVisible = false"><el-icon><Close/></el-icon><span>取消</span></el-button>
    </template> -->

  </el-dialog>
</template>

<script>
import { Check, Close, UploadFilled } from '@element-plus/icons-vue'
import GlobalConfig from '@/config/index.js'

export default {
  name: 'upload-clearance-result-dialog',
  components: {
    // Check, 
    Close, UploadFilled,
  },
  props: [],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,
      isUploading: false,

      // 检验规则
      // formRules: {
      //   name: [{required: true, message: '请输入中文名', trigger: 'blur' }, {max: 20, message: '中文名长度不超过 20 个字符', trigger: 'blur'}],
      //   staff_code: [{required: true, message: '员工编码', trigger: 'blur'}],
      // },
      // 拷贝规则
      // cloneFields: [
        // 'name', 'name_en', 'staff_code', 'campus_id', 'mobile', 'email', 'department_id', 'staff_type_id',
        // {key: 'sex', default: 0},
      // ],
      //
      uploadUrl: GlobalConfig.host + 'api/admin/declaration/clearance/actions/clearance-result-upload',
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode } = {}) {
      this.editMode = editMode || 'insert'
      this.viewVisible = true
      this.isUploading = false

      this.$nextTick(() => {
        this.$refs.uploadView.uploadFiles = []
      })
      
    },
    // 当窗体打开时
    onViewOpen() {
      // this.getPage()
    },

    // 点击触发 - 上传按钮 
    onUploadClick() {
      // console.log(this.$refs.uploadView); return;
      if (this.$refs.uploadView.uploadFiles.length > 0) {
        this.isUploading = true
        this.uploadResult = -1
        this.esDataList = []
        this.$refs.uploadView.submit()
      } else {
        this.$message.error('请先选择需要上传的文件')
      }
    },

    // 当上传失败时 触发
    onUploadSuccess(response, file, fileList) {
      console.log(response.data);
      // this.uploadResult = response.data.error
      if (response.data.code == 0) {
        this.$message.success(response.data.msg)
      }

      // if (response.data.error == 1) {
      //   this.esDataList = response.data.messages
      // } else {
      //   this.$message.success("操作成功")
      // }
      // this.isUploading = false
      // this.$refs.uploadView.uploadFiles = []
      // this.$message.success("操作成功")
    },

    // 当上传失败时 触发
    onUploadError(err, file, fileList) {
      this.isUploading = false
      this.$refs.uploadView.uploadFiles = []
      this.$message.error('上传数据错误')
    },



    // ===================================================================================================

    // 获取校区pair
    // getCampusPairData() {
    //   if (this.campusPairs.length == 0) {
    //     apiGetCampusPairs().then((res) => {
    //       this.campusPairs = res.data.data;
    //     })
    //   }
    // },

  },
  mounted() {
  }
};
</script>

<style scoped>
.m-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.el-form {
  /* width: 00px; */
  margin-left: 0;
  margin-right: auto;
}
.m-file-wrap {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.m-upload-button {
  margin-top: 20px;
  width: 100%;
  height: 42px;
}
.el-upload-dragger {
  width: 100%;
}
</style>
