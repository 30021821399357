
var JxgocsUtils = {

  getOperationList(dataList) {
    var operationMap = new Map()
    if (dataList.length == 0) { return operationMap }
    for (const orderElement of dataList) {
      for (const orderOper of orderElement.operations) { if (operationMap.has(orderOper.code) == false) { operationMap.set(orderOper.code, orderOper) } }
    }
    // console.log(operationMap)
    var resultList = []; for (const iterator of operationMap.keys()) { resultList.push(operationMap.get(iterator)) } return resultList
  },
  
  getBatchOperationDisableMap(operationList, selectionList) {
    const selectionLength = selectionList.length; var operationsDisableMap = new Map(); var operationsCountMap = new Map()
    if (selectionLength == 0) { 
      for (const operElement of operationList) { operationsDisableMap.set(operElement.code, true) } return operationsDisableMap 
    } else {
      for (const operElement of operationList) { operationsDisableMap.set(operElement.code, false); operationsCountMap.set(operElement.code, 0); }
    }
    for (const orderElement of selectionList) {
      for (const operElement2 of orderElement.operations) { var oldValue = operationsCountMap.get(operElement2.code); operationsCountMap.set(operElement2.code, oldValue + 1) }
    }
    for (const operElement of operationList) { if (selectionLength != operationsCountMap.get(operElement.code)) { operationsDisableMap.set(operElement.code, true) } }
    return operationsDisableMap
  },

}

export default JxgocsUtils