<template>
  <span>
    <template v-if="this.state == 2">
      <span class="el-tag el-button--primary el-tag--mini">{{ state }} - 已申报</span>
    </template>
    <template v-else-if="this.state == 0">
      <span class="el-tag el-button--info el-tag--mini">{{ state }} - 未申报</span>
    </template>
    <template v-else-if="this.state == 6">
      <span class="el-tag el-button--success el-tag--mini">{{ state }} - 申报成功</span>
    </template>
    <template v-else-if="this.state == 9">
      <span class="el-tag el-button--primary el-tag--mini">{{ state }} - 已接收</span>
    </template>
    <template v-else>
      <span class="el-tag el-button--info el-tag--mini">{{ state }} - 未知</span>
    </template>
  </span>
</template>

<script>
export default {
  name: "customs-state-tag",
  props: [
    'value',
    'state'
  ],
  data() {
    return {};
  },
  methods: {
  },
  mounted() {
  },
  watch: {
  }
};
</script>

<style scoped>
</style>
