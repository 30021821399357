<template>
  <el-dialog v-model="viewVisible" width="1000px" :show-close="false" custom-class="ep-dialog" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">

    <template #header="{ titleId, titleClass }">
      <div class="m-header">
        <span :id="titleId" :class="titleClass">海南报文操作 - 订单号：{{ mOrderObject.order_sn }}</span>
        <div>
          <!-- <el-button type="primary" plain><el-icon><Link/></el-icon><span>在新窗口打开</span></el-button> -->
          <el-button plain @click="viewVisible = false"><el-icon><Close/></el-icon><span>关闭</span></el-button>
        </div>
      </div>
    </template>

    <el-card>
      <template #header>
        <el-button type="primary" @click="onAddInsert311MessageClick" :loading="false">添加【订单新增报文】</el-button>
        <el-button type="primary" @click="onAddUpdate311MessageClick">添加【订单修改报文】</el-button>
        <!-- <el-button type="primary" size="small" @click="onAddInsert621MessageClick" :loading="false">添加【CEB621新增报文】</el-button> -->
        <!-- <el-button type="primary" size="small" @click="onAddUpdate621MessageClick">添加【CEB621修改报文】</el-button> -->
        <!-- <el-button type="primary" size="small" @click="onAddInsert623MessageClick">添加【CEB623撤销报文】</el-button> -->
      </template>

      <!-- 表格部分 START -->
      <el-table ref="esDataTable" fit stripe highlight-current-row v-loading="epIsLoading" size="small" height="40vh"
          :data="epDataList" :header-cell-style="{background:'#79bbff',color:'#fefefe'}">
        <!-- <el-table-column type="selection" width="50" /> -->
        <el-table-column label="报文GUID" min-width="300">
          <template #default="scope">
            {{ scope.row.message_guid }}
            <div><el-tag>
              <template v-if="scope.row.xml_appType == 2">【修改报文】</template><template v-else>【新增报文】</template>
            </el-tag></div>
            <!-- <div class="ep-cell-subtitle">{{ scope.row.order_sn }}</div> -->
          </template>
        </el-table-column>

        <el-table-column label="报文生成时间" min-width="130" >
          <template #default="scope">
            <span v-html="$$utils.toDataTimeHtml(scope.row.generated_at)" />
          </template>
        </el-table-column>

        <el-table-column label="报文加签信息" min-width="130" >
          <template #default="scope">
            <div v-if="scope.row.is_signed == 1">
              <div><el-tag type="success">已加签</el-tag></div>
              <span v-html="$$utils.toDataTime(scope.row.signed_at)" />
            </div>
            <div v-else>
              <div><el-tag type="info">未加签</el-tag></div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="报文发送信息" min-width="130" >
          <template #default="scope">
            <div v-if="scope.row.is_sended == 1">
              <div><el-tag type="success">已发送</el-tag></div>
              <span v-html="$$utils.toDataTime(scope.row.sended_at)" />
            </div>
            <div v-else>
              <div><el-tag type="info">未发送</el-tag></div>
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column label="报文回执信息" min-width="130" >
          <template #default="scope">
            <div v-if="scope.row.has_got_response == 1">
              <div><el-tag type="success">已接收</el-tag></div>
            </div>
            <div v-else>
              <div><el-tag type="info">未接收</el-tag></div>
            </div>
          </template>
        </el-table-column> -->

        <el-table-column label="操作" min-width="100" >
          <template #default="scope">
            <!-- <div v-if="scope.row.is_signed == 0"> -->
              <span class="ep-action-button primary" @click="onSignClick(scope.row)">加签</span>
            <!-- </div> -->
            <!-- <div v-if="scope.row.is_signed == 1 && scope.row.is_sended == 0"> -->
              <span class="ep-action-button primary" @click="onSendClick(scope.row)">发送</span>

              <!-- <span class="ep-action-button primary" @click="onCheckClick(scope.row)">验签</span> -->
            <!-- </div> -->
          </template>
        </el-table-column>
      </el-table>
      <el-divider />
      <div>
        <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
      </div>
      <!-- 表格部分 END -->

    </el-card>

  </el-dialog>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Close } from '@element-plus/icons-vue'
import { apiGetMessageLogPage, apiInsertMessageLog, apiActionMessageLogSign, apiActionMessageLogSend } from '@/api/plugin_customs.js';
import AuthUtils from '@/utils/auth-utils.js'
import StorageUtils from '@/utils/storage-utils.js'

export default {
  name: 'hn-message-dialog',
  mixins: [ DataTableMixin ],
  components: {
    Close,
  },
  props: [],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,

      order_id: '',
      order_sn: '',
      mOrderObject: {}, // 订单对象

      //
      WEportClient: null,
      //
      mUkeyPassword: '',

      // 检验规则
      // formRules: {
      //   name: [{required: true, message: '请输入中文名', trigger: 'blur' }, {max: 20, message: '中文名长度不超过 20 个字符', trigger: 'blur'}],
      //   staff_code: [{required: true, message: '员工编码', trigger: 'blur'}],
      // },
      // 拷贝规则
      // cloneFields: [
      //   'name', 'name_en', 'staff_code', 'campus_id', 'mobile', 'email', 'department_id', 'staff_type_id',
      //   {key: 'sex', default: 0},
      // ],
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, id, inOrderObject } = {}) {
      this.editMode = editMode || 'insert'
      this.order_id = id
      this.mOrderObject = inOrderObject
      this.viewVisible = true
    },
    // 当窗体打开时
    onViewOpen() {
      this.getPage()
    },
    
    getFilter() {
      let data = {}
      if (this.current_shop_id) {
        data = {shop_id: this.current_shop_id }
      }
      return data
    },
    getPage({reload = false} = {}) {
      // let paginationData = this.getPagination(reload)
      // let filterData = this.getFilter()
      // let searchData = this.$refs.searchView.getSearchFilter()
      // let queryData = Object.assign(paginationData, filterData, searchData)
      let queryData = {order_id: this.order_id}
      this.epIsLoading = true

      apiGetMessageLogPage(queryData).then((res) => {
        this.epDataList = res.data.data
        this.setPaginationTotal(res.data.pagination.total)
        this.epIsLoading = false
      })
    },

    // ===================================================================================================

    // 添加 新增CEB311 报文
    onAddInsert311MessageClick() {
      apiInsertMessageLog({
        order_id: this.order_id,
        message_type: 'CEB311',
        app_type: '1',
      }).then((res) => {
        this.$message.success("操作成功")
        this.getPage()
      })
    },
    // 添加 修改CEB311 报文
    onAddUpdate311MessageClick() {
      apiInsertMessageLog({
        order_id: this.order_id,
        message_type: 'CEB311',
        app_type: '2',
      }).then((res) => {
        this.$message.success("操作成功")
        this.getPage()
      })
    },
    // 添加 新增CEB621 报文
    onAddInsert621MessageClick() {
      apiInsertMessageLog({
        order_id: this.order_id,
        message_type: 'CEB621',
        app_type: '1',
      }).then((res) => {
        this.$message.success("操作成功")
        this.getPage()
      })
    },
    // 添加 修改CEB311 报文
    onAddUpdate621MessageClick() {
      apiInsertMessageLog({
        order_id: this.order_id,
        message_type: 'CEB621',
        app_type: '2',
      }).then((res) => {
        this.$message.success("操作成功")
        this.getPage()
      })
    },
    // 添加 新增CEB623 撤销 报文
    onAddInsert623MessageClick() {
      apiInsertMessageLog({
        order_id: this.order_id,
        message_type: 'CEB623',
        app_type: '1',
      }).then((res) => {
        this.$message.success("操作成功")
        this.getPage()
      })
    },

    // ===================================================================================================

    // 点击触发 - 加签 
    onSignClick(row) {
      // console.log(row)
      let vueThis = this
      var digest_value = ''
      var signature_value = ''
      var key_name = ''

      if (vueThis._CheckUkeyPassword() == false) return
      console.log(row.to_sign_content)
      this.WEportClient.isInstalledTest(this.WEportClient.cusSpcSignDataAsPEM, row.to_sign_content, vueThis.mUkeyPassword, function(msg2, msgJson2){
        console.log(msgJson2)
        if (msg2.Result) {
          // signature_value = '222'
          signature_value = msg2.Data[0]
          key_name = msg2.Data[1]
          console.log(signature_value); console.log(key_name)
          apiActionMessageLogSign({
            log_id: row.log_id,
            message_type: row.message_type,
            // digest_value: digest_value,
            signature_value: signature_value,
            key_name: key_name,
          }).then((res) => {
            vueThis.$message.success("操作成功")
            vueThis.getPage()
          })
        } else {
          vueThis.$message.error(msg2.Error[0])
        }
      })
    },
    // 点击触发 - 发送
    onSendClick(row) {
      let vueThis = this
      apiActionMessageLogSend({
        log_id: row.log_id,
        // order_id: row.order_id,
        // digest_value: digest_value,
        // signature_value: signature_value,
      }).then((res) => {
        vueThis.$message.success("操作成功")
        vueThis.getPage()
      })
    },
    // 验签
    /*
    onCheckClick(row) {
      // console.log(row)
      let vueThis = this
      var digest_value = ''
      var signature_value = ''
      var key_name = ''
      if (vueThis._CheckUkeyPassword() == false) return

      this.WEportClient.isInstalledTest(this.WEportClient.cusSpcVerifySignData, row.message_content, row.xml_SignatureValue, '', function(msg1, msgJson1){
        console.log(msg1)
        console.log(msgJson1)

      })
    },
    */

    // ===================================================================================================

    // 检查Ukey密码
    _CheckUkeyPassword() {
      var ukeyPassword = StorageUtils.get('ukp-' + AuthUtils.getUserId())
      this.mUkeyPassword = ukeyPassword
      if (this.mUkeyPassword == '') {
        this.$message.error("请先输入UKEY密码，然后再尝试获取信息")
        return false
      }
      return true
    },

  },
  mounted() {
    this.WEportClient = window.EportClient
  }
};
</script>

<style scoped>
.m-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
