import { request } from '@/utils/request-utils.js';


// ================================================================================================
// 订单 - 订单
// ================================================================================================

// 获取 平台订单分页
// export function apiV1PlatformWeimobGetOrdersPage(data) {
//     return request({url: `/api/admin/v1/platform/weimob/shops/${data.shop_id}/orders`, method: 'get', params: data, token: true})
// }

// 获取订单分页
export function apiV1GetOrdersPage(data) {
    return request({ url: `/api/merchant/v1/trade/orders`, method: 'get', params: data, token: true })
}

// 获取 订单对象
export function apiV1GetOrderObject(data) {
    return request({ url: `/api/merchant/v1/trade/orders/${data.order_id}`, method: 'get', params: data, token: true })
}

// 添加订单
export function apiInsertOrder(data) {
    return request({
        url: `/api/merchant/trade/good`,
        method: 'post',
        data,
        token: true,
    })
}

// 修改订单
export function apiUpdateOrder(data) {
    return request({
        url: `/api/merchant/trade/order/${data.order_id}`,
        method: 'put',
        data,
        token: true,
    })
}

// 删除订单
export function apiDeleteOrder(data) {
    return request({
        url: `/api/merchant/trade/order/${data.order_id}`,
        method: 'delete',
        data,
        token: true,
    })
}


// 
// 审核
// export function apiActionCheckOrder(data) {
//     return request({
//         url: `/api/merchant/trade/orders/${data.order_id}/action/check`,
//         method: 'post',
//         data,
//         token: true,
//     })
// }

// 批量 订单审核
export function apiOrdersActionCheck(data) {
    return request({
        url: `/api/merchant/trade/orders/action/check`,
        method: 'post',
        data,
        token: true,
    })
}


// 批量 订单作废
export function apiOrdersActionCancel(data) {
    return request({url: `/api/merchant/trade/orders/action/cancel`, method: 'post', data, token: true})
}
// 批量 订单恢复
export function apiOrdersActionUncancel(data) {
    return request({url: `/api/merchant/trade/orders/action/uncancel`, method: 'post', data, token: true})
}
// 批量 店铺订单抓取
export function apiOrdersActionCatch(data) {
    return request({ url: `/api/merchant/trade/orders/action/catch`, method: 'post', data, token: true})
}



// ================================================================================================

// 获取 订单物流扩展信息
export function apiGetOrderLogisticsExtraData(data) {
    return request({
        url: `/api/merchant/trade/orders/${data.order_id}/logistics-extra-data`,
        method: 'get',
        params: data,
        token: true,
    })
}
// 更新 订单物流扩展信息
export function apiSetOrderLogisticsExtraData(data) {
    return request({
        url: `/api/merchant/trade/orders/${data.order_id}/logistics-extra-data`,
        method: 'post',
        data,
        token: true,
    })
}

// ================================================================================================






// ================================================================================================



// test
export function apiActionCatchShopOrder(data) {
    return request({
        url: `/api/merchant/order/shops/${data.shop_id}/catch`,
        method: 'get',
        // params: data,
        token: true,
    })
}



// ================================================================================================
// 订单 - 订单 V1
// ================================================================================================
// 修改 - 订单
export function apiV1UpdateOrder(data) { return request({url: `/api/admin/v1/trade/orders/${data.order_id}`, method: 'put', data, token: true}) }
// 操作 - 分单
export function apiV1SplitOrder(data) { return request({url: `/api/admin/v1/trade/orders/${data.order_id}/actions/split`, method: 'post', data, token: true}) }
