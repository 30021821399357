<template>
  <el-popover placement="top" width="200" trigger="click" :title="tagPopoverTitle" :content="tagPopoverContent">
    <template #reference>
      <el-tag :type="tagType" :effect="effect" class="m-tag">
        <span class="ep-state-tag-icon" :class="`m-background-${tagType}`" v-if="effect != 'dark'" />{{ tagLabel }}
      </el-tag>
    </template>
  </el-popover>
</template>

<script>
export default {
  name: "payment-result-tag",
  components: {
  },
  props: {
    effect: {
      type: String,
      default: 'light' // plain light dark 
    },
    row: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    // Popover标题
    tagPopoverTitle: function() {
      return '支付申报：' + this.getStateText(this.row.payment_result)
    },
    // Popover内容
    tagPopoverContent: function() {
      return this.row.payment_message ? this.row.payment_message : '---'
    },
    tagType() {
      switch (this.row.payment_result) {
        case 0:
          return 'info'
        case 1:
          return ''
        case 2:
          return 'success'
        case 3:
          return 'danger'
        default:
          return 'info'
      }
    },
    tagLabel() {
      return (this.effect == 'dark') ? this.getStateText(this.row.payment_result) : '支付'
    }
  },
  watch: {
  },
  methods: {
    getStateText(inValue) {
      switch (inValue) {
        case 0:
          return '未申报'
        case 1:
          return '申报中'
        case 2:
          return '已通过'
        case 3:
          return '未通过'
        default:
          return '未知'
      }
    },
  },
  mounted() {
  }
};
</script>

<style scoped>
.ep-state-tag-icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}
.m-tag {
  cursor: pointer;
}
.m-background- {
  background: #409eff;
}
.m-background-success {
  background: #67c23a;
}
.m-background-danger {
  background: #f56c6c;
}
.m-background-warning {
  background: #e6a23c;
}
.m-background-info {
  background: #909399;
}
</style>
