<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>申报</el-breadcrumb-item><el-breadcrumb-item>申报管理</el-breadcrumb-item><el-breadcrumb-item>支付申报</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter"></div>
      <div class="ep-page-header-toolbar">
        <!-- <el-button type="primary" @click="onAddClick">添加商户</el-button> -->
      </div>
    </el-header>

    <!-- 其他窗体 START -->
    <OrderDetailDialog ref="refOrderDetailDialog" />
    <!-- 其他窗体 END -->

    <el-main class="ep-page-main">
      <!-- 查询面板 START -->
      <OrderSearchPanel ref="searchView" @search="onSearched" @reset="onSearchReset"/>
      <!-- 查询面板 END -->

      <!-- 数据表格 START -->
      <div class="el-card" style="margin:10px 0 80px 0;">
        <!-- 数据表格-过滤标签栏 START -->
        <el-tabs type="card" v-model="dataTableFilter" @tab-change="onDataTableTabChange">
          <el-tab-pane label="全部" name="__ALL__"></el-tab-pane>
          <el-tab-pane label="未申报" name="0"></el-tab-pane>
          <el-tab-pane label="申报中" name="1"></el-tab-pane>
          <el-tab-pane label="已通过" name="2"></el-tab-pane>
          <el-tab-pane label="未通过" name="3"></el-tab-pane>
        </el-tabs>
        <!-- 数据表格-过滤标签栏 END -->

        <!-- 数据表格 START -->
        <div class="ep-table-panel-wrap">
          <el-table ref="epDataTable" fit stripe highlight-current-row size="small" v-loading="epIsLoading"
            :data="epDataList" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            @selection-change="onTableSelectionChange2">

            <el-table-column type="selection" width="50" />

            <!-- <el-table-column type="expand">
              <template #default="scope">
                <OrderExpandDetailView :id="scope.row.order_id" />
              </template>
            </el-table-column> -->

            <el-table-column label="订单编号" min-width="250">
              <template #default="scope">
                <el-link :underline="false" @click="onDetailClick(scope.row.order_id)">{{ scope.row.order_sn }}</el-link>
                <div class="ep-cell-subtitle">{{ scope.row.main_order_sn }}<el-tag v-if="scope.row.twf_is_auto_workflow == 1" style="margin-left:4px;">自动</el-tag></div>
              </template>
            </el-table-column>

            <!-- <el-table-column label="所属商户/店铺" min-width="150">
              <template #default="scope">
                <span class="ep-overflow-text"><source-code-tag :value="scope.row.source_code" />{{ scope.row.shop_name }}</span>
                <div class="es-cell-subtitle ep-overflow-text">{{ scope.row.merchant_name }}</div>
              </template>
            </el-table-column> -->

            <el-table-column label="下单时间" min-width="100">
              <template #default="scope">
                <span v-html="$$utils.toDataTimeHtml(scope.row.order_time)"></span>
              </template>
            </el-table-column>
            
            <el-table-column label="收件人信息" min-width="120" >
              <template #default="scope">
                <div class="ep-overflow-text">
                  <template v-if="$$auth.hasPermission('A0009')">{{ scope.row.consignee_name }}</template><template v-else>{{ scope.row.mask_consignee_name }}</template>
                </div>
                <div class="ep-overflow-text">
                  <template v-if="$$auth.hasPermission('A0009')">{{ scope.row.consignee_telephone }}</template><template v-else>{{ scope.row.mask_consignee_telephone }}</template>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="pay_amount" label="支付金额" min-width="80" />

            <!-- <el-table-column label="订单状态" min-width="100" >
              <template #default="scope">
                <order-state-tag :state="scope.row.order_state" />
              </template>
            </el-table-column> -->

            <el-table-column label="支付申报" min-width="100" >
              <template #default="scope">
                <PaymentResultTag effect="dark" :row="scope.row" />
              </template>
            </el-table-column>

            
            <el-table-column label="操作" min-width="150" fixed="right">
              <template #default="scope">

                <template v-for="(operElement) in scope.row.operations" :key="operElement.code">
                  <span class="ep-action-button primary" @click="onPluginOperClick(operElement.code, [scope.row.order_id]), scope.row">{{ operElement.name }}</span>
                </template>

                <template v-if="scope.row.order_state != 5 && scope.row.payment_result == 3">
                  <span class="ep-action-button danger" @click="onCancelClick([scope.row.order_id])">取消</span>
                </template>

                <!-- <div>
                  <el-dropdown trigger="click" @command="onMoreButtonClick">
                    <span class="es-action-button primary">更多<el-icon class="el-icon--right"><ArrowDown /></el-icon></span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item :command="{index: 'redeclare', id: scope.row.order_id}">重报</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>  
                  </el-dropdown>
                </div> -->

              </template>
            </el-table-column>
            
          </el-table>
        </div>
        <!-- 数据表格 END -->
      </div>
      <!-- 数据表格面板 END -->


      <!-- 数据页面固定footer START -->
      <div class="ep-float-footer">
        <div class="ep-float-footer-toolbar">
          <el-button plain class="ep-button-select-all" @click="$refs.epDataTable.toggleAllSelection()">
            <span class="ep-button-select-all-text1">已选中 <span class="ep-button-select-all-text2">{{ epMultipleSelection.length }}</span> / <span class="ep-button-select-all-text2">{{ epDataList.length }}</span> 项</span>
          </el-button>

          <!-- 动态批量操作 START -->
          <template v-for="(oper) in operationList" :key="oper">
            <el-button type="primary" :disabled="batchOperationDisableMap.get(oper.code)" @click="onPluginOperClick(oper.code)">{{ oper.name }}</el-button> 
          </template>
          <!-- 动态批量操作 END -->
          
        </div>
        <div class="ep-float-footer-pager">
          <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
        </div>
      </div>
      <!-- 数据页面固定footer END -->

    </el-main>

  </el-container>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Edit, ArrowDown, MoreFilled } from '@element-plus/icons-vue'
import OrderDetailDialog from '@/views/v1/declaration/order/common/OrderDetailDialog'
import OrderSearchPanel from '@/views/v1/declaration/order/common/OrderSearchPanel'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import OrderStateTag from '@/components/v1/trade/tags/OrderStateTag'
import CustomsResultTag from '@/components/v1/declaration/tags/CustomsResultTag'
import PaymentResultTag from '@/components/v1/declaration/tags/PaymentResultTag'
import { apiGetOrdersDeclarationPage, apiActionPaymentDeclare, apiActionPaymentQuery } from '@/api/declaration.js'
import { apiV1GetOrdersDeclarationPage } from '@/api/declaration.js'
import { apiOrdersActionCancel } from '@/api/trade.js'
import AuthUtils from '@/utils/auth-utils.js'
import JxgocsUtils from '@/utils/jxgocs-utils.js'
import GlobalConfig from '@/config/index.js'


export default {
  mixins: [ DataTableMixin ],
  name: "order-list-page",
  components: {
    // Edit,
    ArrowDown,
    OrderDetailDialog,
    OrderSearchPanel,
    SourceCodeTag,
    OrderStateTag,
    CustomsResultTag,
    PaymentResultTag,
  },
  props: [],
  data() {
    return {
      // shop_id: '',
      // current_merchant_id: '',
      // current_shop_id: '',
      dataTableFilter: '__ALL__',
      //
      operationList: [],
      batchOperationDisableMap: new Map(),
    }
  },
  computed: {},
  watch: {},
  methods: {
    getFilter() {
      let data = {
        shop_id: AuthUtils.getCurrentShopId(),
        declaration_type: 'payment',
      }
      if (this.dataTableFilter != '' && this.dataTableFilter != '__ALL__') {
        data.payment_result = this.dataTableFilter
      }
      return data
    },
    getPage({reload = false} = {}) {
      let paginationData = this.getPagination(reload)
      let filterData = this.getFilter()
      let searchData = this.$refs.searchView.getSearchFilter() 
      let queryData = Object.assign(paginationData, filterData, searchData)

      this.epIsLoading = true
      apiV1GetOrdersDeclarationPage(queryData).then((res) => {
        this.epDataList = res.data.data
        this.setPaginationTotal(res.data.pagination.total)
        this.operationList = JxgocsUtils.getOperationList(this.epDataList)
        this.batchOperationDisableMap = JxgocsUtils.getBatchOperationDisableMap(this.operationList, this.epMultipleSelection)
        this.epIsLoading = false
      })
    },

    // ===================================================================================================
    
    // 回调触发 - 查询
    onSearched(data) {
      this.getPage({reload: true})
    },
    // 回调触发 - 查询重置
    onSearchReset() {
      this.getPage({reload: true})
    },
    // Change触发 - 数据表Tab
    onDataTableTabChange(name) {
      this.getPage({reload: true})
    },
    // 点击触发 - 订单详情
    onDetailClick(id) {
      this.$refs.refOrderDetailDialog.showView({ editMode: "update", id: id })
    },
    // 表格选择触发 - 替换默认多选实现动态菜单
    onTableSelectionChange2(val) {
      this.epMultipleSelection = val
      this.batchOperationDisableMap = JxgocsUtils.getBatchOperationDisableMap(this.operationList, this.epMultipleSelection)
    },
    // 点击触发 - 插件操作
    onPluginOperClick(code, inIds = [], rowObj = null) {
      // console.log(code); console.log(inIds)
      switch (code) {
        case 'payment.declare':
          this.onDeclareClick(inIds)
          break;
        case 'payment.query':
          this.onQueryClick(inIds)
          break;
      }
    },

    // ===================================================================================================

    // 点击触发 - 申报
    onDeclareClick(inIds = [], execParams1 = 0) {
      var ids = this.getTableSelectedIds(inIds, (val) => val.order_id)
      var apiParams = { ids: ids }
      if (execParams1 != 0) {
        apiParams.exec_params1 = execParams1
      }
      apiActionPaymentDeclare(apiParams).then((res) => {
        this.getPage()
        this.$message.success("操作成功")
      })
    },
    // 点击触发 - 检索
    onQueryClick(inIds = []) {
      var ids = this.getTableSelectedIds(inIds, (val) => val.order_id)
      apiActionPaymentQuery({
        ids: ids,
      }).then((res) => {
        this.getPage()
        this.$message.success("操作成功")
      })
    },
    // 更多按钮触发
    // onMoreButtonClick(commandObj) {
    //   // console.log(commandObj)
    //   switch (commandObj.index) {
    //     case 'redeclare':
    //       this.onDeclareClick([commandObj.id], 1)
    //       break;
    //   }
    // },

    // 点击取消时触发
    onCancelClick(inIds = []) {
      var ids = []
      if (inIds.length > 0) {
        ids = inIds
      } else {
        var selection = this.getMultipleSelection()
        selection.forEach((value) => {
          ids.push(value.order_id)
        });
      }

      this.$confirm("确定取消选定的订单吗?", "提示", {
        type: "warning"
      }).then(() => {
        apiOrdersActionCancel({
          ids: ids,
        }).then((res) => {
          this.getPage()
          this.$message.success("操作成功")
        })
      }).catch(()=>{})
    },

    // ===================================================================================================


  },
  mounted() {
    this.$nextTick(() => {
      this.getPage()
    })

    // this.$$emitter.on('shopIdChanged', (shop_id) => {
    //   // console.log(shop_id)
    //   this.current_shop_id = shop_id
    //   this.getPage()
    // })
  }
}
</script>

<style>
</style>
