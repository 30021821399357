<template>
  <el-dialog v-model="viewVisible" width="1000px" :show-close="false" class="ep-dialog" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">

    <template #header="{ titleId, titleClass }">
      <div class="m-header">
        <span :id="titleId" :class="titleClass">订单物流轨迹：<span v-if="epIsLoading">加载中...</span><span v-else>{{ orderData.tradeId }}</span></span>
        <div>
          <el-button type="primary" plain @click="onViewOpen"><el-icon><Refresh/></el-icon><span>刷新</span></el-button>
          <el-button plain @click="viewVisible = false"><el-icon><Close/></el-icon><span>关闭</span></el-button>
        </div>
      </div>
    </template>

    <el-skeleton :rows="8" :loading="epIsLoading" animated>
      <template #default>
        <!-- 主要内容区域 START -->
        <el-row :gutter="10">
          <el-col :span="6">
            <el-card :body-style="{ padding: '10px' }">
              <el-descriptions :column="1" direction="vertical" border size="small">
                <el-descriptions-item><template #label>LP单号</template>{{ orderData.orderCode }}</el-descriptions-item>
                <el-descriptions-item><template #label>快递公司</template>
                  <div style="display: flex;">
                    <el-avatar shape="square" :size="40" fit="contain" :src="orderData.partner_partnerLogoUrl" />
                    <span style="line-height: 40px; margin-left: 10px;">{{ orderData.partner_partnerName }}</span>
                  </div>
                </el-descriptions-item>
                <!-- <el-descriptions-item><template #label>运单号</template>{{ orderData.mailNo }}</el-descriptions-item> -->
                <el-descriptions-item><template #label>包裹状态</template>{{ orderData.packageStatus_logisticStatusDesc }}</el-descriptions-item>
              </el-descriptions>
            </el-card>
          </el-col>

          <!-- 物流轨迹 START -->
          <el-col :span="18">
            <el-card>
              <template #header>
                运单号：{{ orderData.mailNo }}
              </template>
              <el-scrollbar height="400px">
                <el-timeline>
                  <el-timeline-item v-for="(transit, index) in orderData.transitList" :key="index" :timestamp="transit.time" 
                    :size="(index == 0) ? 'large':'normal'" :type="(index == 0) ? 'primary':'info'" 
                    :hollow="(index == orderData.transitList.length - 1) ? true : false"
                    placement="top">
                    <span>{{ transit.message }}</span>
                    <el-alert type="info" :closable="false" v-if="transit.operator || transit.country || transit.city" style="margin-top: 5px;">
                      <template #title>
                        <span v-if="transit.operator" class="t1">操作人：{{ transit.operator }}</span>
                        <span v-if="transit.country" class="t1">地点：{{ transit.country }}</span>
                        <span v-if="transit.city" class="t1">地点：{{ transit.city }}</span>
                      </template>
                    </el-alert>
                  </el-timeline-item>
                </el-timeline>
              </el-scrollbar>
            </el-card>
          </el-col>
          <!-- 物流轨迹 END -->
        </el-row>

        
        <!-- 主要内容区域 END --> 
      </template>
    </el-skeleton>

    <!-- <template #footer>
      <el-button type="primary" @click="onSubmit"><el-icon><Check/></el-icon><span>提交</span></el-button>
      <el-button type="default" @click="viewVisible = false"><el-icon><Close/></el-icon><span>取消</span></el-button>
    </template> -->
  </el-dialog>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Refresh, Check, Close } from '@element-plus/icons-vue'
import { apiV1CainiaoLogisticsQueryLogisticsDetail } from '@/api/plugin_logistics.js'


export default {
  mixins: [ DataTableMixin ],
  name: 'order-detail-dialog',
  components: {
    Refresh, Check, Close,
  },
  props: [],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,

      order_id: '',
      orderData: [],


      // 检验规则
      formRules: {
        name: [{required: true, message: '请输入中文名', trigger: 'blur' }, {max: 20, message: '中文名长度不超过 20 个字符', trigger: 'blur'}],
        staff_code: [{required: true, message: '员工编码', trigger: 'blur'}],
      },
      // 拷贝规则
      cloneFields: [
        'name', 'name_en', 'staff_code', 'campus_id', 'mobile', 'email', 'department_id', 'staff_type_id',
        {key: 'sex', default: 0},
      ],
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, id } = {}) {
      this.editMode = editMode || 'insert'
      this.order_id = id
      // this.order_sn = order_sn
      this.viewVisible = true
    },
    // 当View打开时
    onViewOpen() {

      this.epIsLoading = true

      apiV1CainiaoLogisticsQueryLogisticsDetail({
        order_id: this.order_id,
      }).then((res) => {
        if (res.data.code != 0) {
          var message = res.data.message ? res.data.message : '获取物流轨迹失败'
          this.$message.error(message)
        } else {
          this.orderData = res.data.data
          this.epIsLoading = false
        }
        
      })
    },
    

    // ===================================================================================================


  },
  mounted() {
  }
};
</script>

<style scoped>
.m-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.m-amount-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* color: #303133; */
}
.m-discount-text {
  color: #67C23A;
}
.m-total-text {
  color: #f56C6C;
  font-weight: 700;
}

.scrollbar-demo-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
  background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
}
.t1+.t1 { margin-left: 20px; }
</style>
