import EpPagination from '@/components/v1/common/table/EpPagination';
// import EsSearchBar from '@/components/common/datatable/EsSearchBar';


const dataTable = {
  components: {
    EpPagination, 
    // EsSearchBar,
  },
  data() {
    return {
      // 表格数据
      epDataList: [],
      // 多选内容
      epMultipleSelection: [],
      // 数据是否加载中
      epIsLoading: false,
      //
      epPage: 1,
      epTotal: 0,
      epPageSize: 20,
    }
  },
  methods: {
    onTableSelectionChange(val) {
      // console.log(val)
      this.epMultipleSelection = val
    },
    // 分页组件改变触发事件
    onPaginationChange(event, args) {
      // console.log(event);console.log(args);
      if (event === 'page-change') {
        // this.getPage({page: args})
        this.epPage = args
      } else if (event === 'limit-change') {
        // this.getPage({limit: args})
        this.epPage = 1
        this.epPageSize = args
      }
      this.getPage()
    },

    // 获取filter参数
    getFilter() {
      return {}
    },

    // 获取pagination参数
    getPagination(reload = false) {
      if (reload) {
        this.epPage = 1
      }
      return {
        page: this.epPage,
        limit: this.epPageSize
      }
    },
    // 设置pagination参数
    setPagination({page = 1, limit = 10}) {
      this.epPage = page
      this.epPageSize = limit
    },
    // 设置page参数 - 当前页参数
    setPaginationPage(page = 1) {
      // let inPage = parseInt(page) || 1
      this.epPage = page
    },
    // 设置总页数
    setPaginationTotal(total) {
      this.epTotal = total
    },

    // 刷新表格数据
    getPage() {
    },

    // 获取选中项目
    getMultipleSelection() {
      return this.epMultipleSelection
    },

    getMultipleSelectionCount() {
      return this.epMultipleSelection.length
    },

    // =======================================================================================================
    // filter改变时触发事件
    onFilterChange() {
      this.getPage()
    },

    // 点击搜索
    onSearch() {
      this.getPage()
    },


    // =======================================================================================================
    resolveDeleteConfirm() {
      return this.$confirm("确定删除该数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
    },

    // =======================================================================================================

    getSelectedIds(rowArray = [], funGetId) {
      var ids = []
      var selection = (rowArray.length > 0) ? rowArray : this.getMultipleSelection()
      selection.forEach((value) => {
        ids.push(funGetId(value))
      });
      return ids.join(',')
    },

    getTableSelectedIds(idArray = [], funGetId)
    {
      var ids = []
      if (idArray.length > 0) { ids = idArray } else { var selection = this.getMultipleSelection(); selection.forEach((value) => { ids.push(funGetId(value)) }) }
      return ids.join(',') 
    }

    // =======================================================================================================
    // bak
    // data = Object.assign(data, where || {});
  }
}

export default dataTable