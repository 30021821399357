<template>
  <el-tag :type="tagType" :effect="effect">
    <span class="ep-state-tag-icon" :class="`m-background-${tagType}`" />{{ tagLabel }}
  </el-tag>
</template>

<script>
export default {
  name: "order-state-tag",
  components: {
  },
  props: {
    state: {
      type: Number,
    },
    effect: {
      type: String,
      default: 'light' // plain light dark 
    },
  },
  data() {
    return {};
  },
  computed: {
    tagType() {
      switch (this.state) {
        case 0:
          return 'info'
        case 1:
          return ''
        case 2:
          return ''
        case 3:
          return 'success'
        case 4:
          return 'danger'
        case 5:
          return 'warning'
        default:
          return 'info'
      }
    },
    tagLabel() {
      switch (this.state) {
        case 0:
          return '未接单'
        case 1:
          return '已确认'
        case 2:
          return '申报中'
        case 3:
          return '申报成功'
        case 4:
          return '申报失败'
        case 5:
          return '已取消'
        default:
          return '未知'
      }
    }

  },
  watch: {
  },
  methods: {
  },
  mounted() {
  }
};
</script>

<style scoped>
.ep-state-tag-icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}
.m-background- {
  background: #409eff;
}
.m-background-success {
  background: #67c23a;
}
.m-background-danger {
  background: #f56c6c;
}
.m-background-warning {
  background: #e6a23c;
}
.m-background-info {
  background: #909399;
}
</style>
