<template>
  <el-dialog v-model="viewVisible" width="400px" :show-close="false" custom-class="ep-dialog" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">

    <template #header="{ titleId, titleClass }">
      <div class="m-header">
        <span :id="titleId" :class="titleClass">推送仓库：</span>
        <div>
          <!-- <el-button type="primary" plain><el-icon><Link/></el-icon><span>在新窗口打开</span></el-button> -->
          <el-button plain @click="viewVisible = false"><el-icon><Close/></el-icon><span>关闭</span></el-button>
        </div>
      </div>
    </template>

    <el-card>
      <div class="ep-dialog-region-title">清关状态</div>
      <el-radio-group v-model="clearanceResult">
        <el-radio :label="7">7（放行）</el-radio>
        <el-radio :label="500">500（查验）</el-radio>
      </el-radio-group>
    </el-card>

    <template #footer>
      <el-button type="primary" @click="onSendWarehouseClick"><el-icon><Check/></el-icon><span>提交</span></el-button>
    </template>

  </el-dialog>
</template>

<script>
import { Check, Close } from '@element-plus/icons-vue'
import { apiActionWarehouseDeclare } from '@/api/declaration.js'
import GlobalConfig from '@/config/index.js'

export default {
  name: 'lgs-declare-dialog',
  components: {
    Close, Check,
  },
  emits: ['submit'],
  props: [],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,
      isUploading: false,
      //
      clearanceResult: '',
      currentInIds: [],
      // 检验规则
      // formRules: {
      //   name: [{required: true, message: '请输入中文名', trigger: 'blur' }, {max: 20, message: '中文名长度不超过 20 个字符', trigger: 'blur'}],
      //   staff_code: [{required: true, message: '员工编码', trigger: 'blur'}],
      // },
      // 拷贝规则
      // cloneFields: [
        // 'name', 'name_en', 'staff_code', 'campus_id', 'mobile', 'email', 'department_id', 'staff_type_id',
        // {key: 'sex', default: 0},
      // ],

      //
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, inIds } = {}) {
      this.editMode = editMode || 'insert'
      // this.order_id = id
      // this.order_sn = order_sn
      this.viewVisible = true
      this.isUploading = false

      this.currentInIds = inIds
      this.clearanceResult = 7

      this.$nextTick(() => {
        // this.$refs.uploadView.uploadFiles = []
      })
      
    },
    // 当窗体打开时
    onViewOpen() {
      // this.getPage()
    },


    onSendWarehouseClick() {
      // console.log(this.clearanceResult)
      var ids = this.getSelectIds(this.currentInIds)

      apiActionWarehouseDeclare({
        ids: ids,
        clearanceResult: this.clearanceResult
      }).then((res) => {
        this.$emit('submit')
        this.viewVisible = false;
        this.$message.success("操作成功")
      })
    },



    // ===================================================================================================



  },
  mounted() {
  }
};
</script>

<style scoped>
.m-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.el-form {
  /* width: 00px; */
  margin-left: 0;
  margin-right: auto;
}
</style>
