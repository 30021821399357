<template>
  <el-dialog v-model="viewVisible" width="1000px" :show-close="false" custom-class="ep-dialog" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">

    <template #header="{ titleId, titleClass }">
      <div class="m-header">
        <span :id="titleId" :class="titleClass">订单详情：<span v-if="epIsLoading">加载中...</span><span v-else>{{ orderData.order_sn }}</span></span>
        <div>
          <!-- <el-button type="primary" plain><el-icon><Link/></el-icon><span>在新窗口打开</span></el-button> -->
          <el-button plain @click="viewVisible = false"><el-icon><Close/></el-icon><span>关闭</span></el-button>
        </div>
      </div>
    </template>

    <el-skeleton :rows="8" :loading="epIsLoading" animated>
      <template #default>
        <!-- 主要内容区域 START --> 

        <el-row :gutter="0">
          <el-col :span="18">
            <el-card>
              <template #header>

                <template v-if="$$auth.hasPermission('A0009')">
                  <el-descriptions :column="3" direction="vertical" border size="small">
                    <el-descriptions-item><template #label>订购人姓名</template>{{ orderData.payer_name }}</el-descriptions-item>
                    <el-descriptions-item><template #label>订购人电话</template>{{ orderData.payer_telephone }}</el-descriptions-item>
                    <el-descriptions-item><template #label>订购人身份证</template>{{ orderData.payer_idnumber }}</el-descriptions-item>

                    <el-descriptions-item><template #label>收件人姓名</template>{{ orderData.consignee_name }}</el-descriptions-item>
                    <el-descriptions-item :span="2"><template #label>收件人电话</template>{{ orderData.consignee_telephone }}</el-descriptions-item>

                    <el-descriptions-item :span="2"><template #label>收货人区域</template>
                      <span>{{ orderData.consignee_addr_province_text }} - {{ orderData.consignee_addr_city_text }} - {{ orderData.consignee_addr_district_text }} （{{ orderData.consignee_addr_area_code }}）</span>
                    </el-descriptions-item>

                    <el-descriptions-item :span="1"><template #label>收件人地址</template>{{ orderData.consignee_short_address }}</el-descriptions-item>
                  </el-descriptions>
                </template>
                <template v-else>
                  <el-descriptions :column="3" direction="vertical" border size="small">
                    <el-descriptions-item><template #label>订购人姓名</template>{{ orderData.mask_payer_name }}</el-descriptions-item>
                    <el-descriptions-item><template #label>订购人电话</template>{{ orderData.mask_payer_telephone }}</el-descriptions-item>
                    <el-descriptions-item><template #label>订购人身份证</template>{{ orderData.mask_payer_idnumber }}</el-descriptions-item>

                    <el-descriptions-item><template #label>收件人姓名</template>{{ orderData.mask_consignee_name }}</el-descriptions-item>
                    <el-descriptions-item :span="2"><template #label>收件人电话</template>{{ orderData.mask_consignee_telephone }}</el-descriptions-item>

                    <el-descriptions-item :span="2"><template #label>收货人区域</template>
                      <span>{{ orderData.consignee_addr_province_text }} - {{ orderData.consignee_addr_city_text }} - {{ orderData.consignee_addr_district_text }} （{{ orderData.consignee_addr_area_code }}）</span>
                    </el-descriptions-item>

                    <el-descriptions-item :span="1"><template #label>收件人地址</template>{{ orderData.mask_consignee_short_address }}</el-descriptions-item>
                  </el-descriptions>
                </template>

              </template>

              <!-- 商品信息 START -->
              <el-table ref="esDataTable" fit stripe highlight-current-row v-loading="epIsLoading" size="small"
                :data="orderData.orderGoods" :header-cell-style="{background:'#79bbff',color:'#fefefe'}">

                <el-table-column type="index" width="50" />
                
                <el-table-column label="商品名称" min-width="200" >
                  <template #default="scope">
                    {{ scope.row.sku_name }}
                    <div v-if="scope.row.declaration_tag_name != ''"><el-tag type="success">{{ scope.row.declaration_tag_name }}</el-tag></div>
                    <!-- <div class="es-cell-subtitle">{{ scope.row.goods_id }}</div> -->
                  </template>
                </el-table-column>
                
                <el-table-column prop="goods_sn" label="商品编码" min-width="100" />
                <el-table-column prop="goods_sku" label="规格编码" min-width="100" />
                <el-table-column prop="price" label="商品单价" min-width="100" />
                <el-table-column prop="quantity" label="数量" min-width="100" />
              </el-table>
              <!-- 商品信息 END -->
              
            </el-card>

          </el-col>

          <!-- 价格信息 START -->
          <el-col :span="6">
            <div style="padding: 20px">
              <div class="m-amount-row"><span>商品总价：</span><span>¥ {{ orderData.goods_amount }}</span></div>
              <div class="m-amount-row"><span>税费：</span><span>¥ {{ orderData.cat_tax }}</span></div>
              <div class="m-amount-row"><span>运费：</span><span>¥ {{ orderData.shipping_fee }}</span></div>
              <div class="m-amount-row"><span>优惠：</span><span class="m-discount-text">¥ {{ orderData.deductible_amount }}</span></div>
              <el-divider />
              <div class="m-amount-row"><span>实际支付：</span><span class="m-total-text">¥ {{ orderData.pay_amount }}</span></div>
            </div>
          </el-col>
          <!-- 价格信息 END -->
          
        </el-row>
        <!-- 主要内容区域 END --> 
      </template>
    </el-skeleton>

    <!-- <template #footer>
      <el-button type="primary" @click="onSubmit"><el-icon><Check/></el-icon><span>提交</span></el-button>
      <el-button type="default" @click="viewVisible = false"><el-icon><Close/></el-icon><span>取消</span></el-button>
    </template> -->
  </el-dialog>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Link, Check, Close } from '@element-plus/icons-vue'
import { apiV1GetOrderObject } from '@/api/trade.js'


export default {
  mixins: [ DataTableMixin ],
  name: 'order-detail-dialog',
  components: {
    Link, Check, Close,
  },
  props: [],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,

      order_id: '',
      orderData: [],


      // 检验规则
      formRules: {
        name: [{required: true, message: '请输入中文名', trigger: 'blur' }, {max: 20, message: '中文名长度不超过 20 个字符', trigger: 'blur'}],
        staff_code: [{required: true, message: '员工编码', trigger: 'blur'}],
      },
      // 拷贝规则
      cloneFields: [
        'name', 'name_en', 'staff_code', 'campus_id', 'mobile', 'email', 'department_id', 'staff_type_id',
        {key: 'sex', default: 0},
      ],
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, id } = {}) {
      this.editMode = editMode || 'insert'
      this.order_id = id
      // this.order_sn = order_sn
      this.viewVisible = true
    },
    // 当View打开时
    onViewOpen() {

      this.epIsLoading = true

      apiV1GetOrderObject({
        order_id: this.order_id,
      }).then((res) => {
        // this.formModel = this.$$utils.cloneObject(res.data.data, this.cloneFields)
        this.orderData = res.data.data
        this.epIsLoading = false
      })
    },
    

    // ===================================================================================================

    // 获取校区pair
    // getCampusPairData() {
    //   if (this.campusPairs.length == 0) {
    //     apiGetCampusPairs().then((res) => {
    //       this.campusPairs = res.data.data;
    //     })
    //   }
    // },

  },
  mounted() {
  }
};
</script>

<style scoped>
.m-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.m-amount-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* color: #303133; */
}
.m-discount-text {
  color: #67C23A;
}
.m-total-text {
  color: #f56C6C;
  font-weight: 700;
}
</style>
