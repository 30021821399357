import { request } from '@/utils/request-utils.js';

// ================================================================================================
// 顺丰物流
// ================================================================================================

// 获取 顺丰订单面单打印文件
export function apiSfexpressLogisticsGetPrintFile(data) {
    return request({
        url: `/api/admin/logistics/sfexpress/print-file`,
        method: 'get',
        params: data,
        token: true,
    })
}



// ================================================================================================
// 物流 - 菜鸟物流
// ================================================================================================

// 获取 平台订单分页
export function apiV1CainiaoLogisticsQueryLogisticsDetail(data) { return request({url: `/api/admin/v1/logistics/cainiao/logistics-detail/${data.order_id}`, method: 'get', params: data, token: true}) }
