import AuthUtils from '@/utils/auth-utils.js'

/* V1旧版，以数组为权限值
var permission = {
  // 指令的定义
  mounted(el, binding) {
    console.log(binding)
    // console.log(el)
    const { value } = binding

    if (value && value instanceof Array && value.length > 0) {
      let hasPermission = false
      let oper = binding.arg || 'and'

      if (oper === 'or') {
        hasPermission = value.some((value) => {
          return AuthUtils.hasPermission(value)
        })
      } else if (oper === 'and') {
        hasPermission = value.every((value) => {
          return AuthUtils.hasPermission(value)
        })
      }
      // console.log(hasPermission)

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      // throw new Error(`need permissions! Like v-permission="['M10','M11']"`)
      // 暂时修改成，当权限参数为空时，不受限制
      return true
    }
  }
}
*/

// V1旧版，以字符串为权限值
var permission = {
  // 指令的定义
  mounted(el, binding) {
    // console.log(binding)
    // console.log(el)
    const { value } = binding

    if (value && value != '') {
      let hasPermission = false
      let oper = binding.arg || 'and'
      var valueArray = value.split(','); 
      // console.log(value)
      if (oper === 'or') {
        hasPermission = valueArray.some((value) => {
          return AuthUtils.hasPermission(value)
        })
      } else if (oper === 'and') {
        hasPermission = valueArray.every((value) => {
          return AuthUtils.hasPermission(value)
        })
      }
      // console.log(hasPermission)

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      // throw new Error(`need permissions! Like v-permission="M10,M11"`)
      // 暂时修改成，当权限参数为空时，不受限制
      return true
    }
  }
}

export default permission