<template>
  <el-dialog v-model="viewVisible" width="1000px" :show-close="false" custom-class="ep-dialog" :append-to-body="true" :close-on-click-modal="false" @open="onViewOpen">

    <template #header="{ titleId, titleClass }">
      <div class="m-header">
        <span :id="titleId" :class="titleClass">广州关申报轨迹 - 订单号：{{ order_sn }}</span>
        <div>
          <!-- <el-button type="primary" plain><el-icon><Link/></el-icon><span>在新窗口打开</span></el-button> -->
          <el-button plain @click="viewVisible = false"><el-icon><Close/></el-icon><span>关闭</span></el-button>
        </div>
      </div>
    </template>

    <el-skeleton :rows="8" :loading="epIsLoading" animated>
      <template #default>
        <!-- 主要内容区域 START --> 
        <el-card>

          <el-table ref="esDataTable" fit stripe highlight-current-row v-loading="epIsLoading" size="small"
            :data="epDataList" :header-cell-style="{background:'#79bbff',color:'#fefefe'}">

            <!-- <el-table-column type="selection" width="50" /> -->
            <el-table-column label="平台业务单编号" min-width="200">
              <template #default="scope">
                {{ scope.row.orderNo }}
                <!-- <div class="es-cell-subtitle">{{ scope.row.order_sn }}</div> -->
              </template>
            </el-table-column>

            <el-table-column label="平台状态" min-width="100" >
              <template #default="scope">
                <customs-state-tag :state="scope.row.state" />
              </template>
            </el-table-column>

            <el-table-column label="海关状态" min-width="100" >
              <template #default="scope">
                <customs-state-tag :state="scope.row.cusState" />
              </template>
            </el-table-column>

            <el-table-column label="国检状态" min-width="100" >
              <template #default="scope">
                <customs-state-tag :state="scope.row.ciqState" />
              </template>
            </el-table-column>

            <el-table-column prop="cusReceiptContent" label="海关回执内容" min-width="200" />

          </el-table>

        </el-card>
      </template>
    </el-skeleton>

  </el-dialog>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Close } from '@element-plus/icons-vue'
import CustomsStateTag from '@/views/v1/declaration/customs/guangzhou/CustomsStateTag'
import { apiGetCustomsDeclarationPage } from '@/api/declaration.js';

export default {
  name: 'gz-query-dialog',
  mixins: [ DataTableMixin ],
  components: {
    Close,
    CustomsStateTag,
  },
  props: [],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,
      //
      order_id: '',
      order_sn: '',
      
      // 检验规则
      // formRules: {
      //   name: [{required: true, message: '请输入中文名', trigger: 'blur' }, {max: 20, message: '中文名长度不超过 20 个字符', trigger: 'blur'}],
      //   staff_code: [{required: true, message: '员工编码', trigger: 'blur'}],
      // },
      // 拷贝规则
      // cloneFields: [
      //   'name', 'name_en', 'staff_code', 'campus_id', 'mobile', 'email', 'department_id', 'staff_type_id',
      //   {key: 'sex', default: 0},
      // ],
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, id, order_sn } = {}) {
      this.editMode = editMode || 'insert'
      this.order_id = id
      this.order_sn = order_sn
      this.viewVisible = true
    },
    // 当窗体打开时
    onViewOpen() {
      this.getPage()
    },
    
    // ===================================================================================================

    getFilter() {
      return {}
    },
    getPage({reload = false} = {}) {
      // let paginationData = this.getPagination(reload)
      // let filterData = this.getFilter()
      // let searchData = this.$refs.searchView.getSearchFilter()
      // let queryData = Object.assign(paginationData, filterData, searchData)
      let queryData = {order_id: this.order_id}

      this.esIsLoading = true
      apiGetCustomsDeclarationPage(queryData).then((res) => {
        this.epDataList = res.data.data
        // this.setPaginationTotal(res.data.pagination.total)
        this.epIsLoading = false
      })
    },


    // ===================================================================================================

  },
  mounted() {
  }
};
</script>

<style scoped>
.m-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
